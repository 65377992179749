import { Dialog, Transition } from "@headlessui/react"
import { FC, Fragment } from "react"
import { createPortal } from "react-dom"
import { classNames } from "utils"

const themes = {
    white: { bg: "bg-white", card: "border border-border shadow-sm" },
    gray: { bg: "bg-gray-500", card: "shadow-xl" }
}

type DialogProps = Parameters<typeof Dialog>[0]
export type ModalProps = DialogProps & {
    show: boolean
    scrollAuto?: boolean
    noScroll?: boolean
    cardClassName?: string
    theme?: keyof typeof themes
    centered?: boolean
}

const contentWrapperId = "modal-content-wrapper"

const Modal: FC<ModalProps> = function Modal({
    show,
    onClose,
    scrollAuto = false,
    noScroll = false,
    theme = "gray",
    children,
    cardClassName,
    centered = false,
    ...props
}) {
    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="fixed z-50 inset-0" onClose={onClose} {...props}>
                <div
                    id={contentWrapperId}
                    className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0"
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay
                            className={classNames("fixed inset-0 bg-opacity-75 transition-opacity", themes[theme].bg)}
                        />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    {centered && (
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                    )}
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className={classNames(
                                `relative inline-block align-bottom max-h-9/10screen bg-white rounded-lg text-left transform transition-all sm:align-middle`,
                                !noScroll && (scrollAuto ? "overflow-y-auto" : "overflow-y-scroll"),
                                cardClassName,
                                themes[theme].card,
                                !centered && "mt-[6vh]",
                                centered && "!absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                            )}
                        >
                            {children}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export const ModalCompatiblePortal: FC = ({ children }) =>
    createPortal(children, document.querySelector(`#${contentWrapperId}`) ?? document.querySelector("body")!)

export default Modal
