import { formatDate } from "components/ContentLibrary/utils"
import { FormControlProps } from "forms"
import DateSelector, { DateSelectorWithIcons } from "forms/DateSelector"
import { CalendarProps } from "forms/DateSelector/Calendar"
import { useTranslation } from "react-i18next"
import InputMask from "react-input-mask"
import { useCurrentPreferences } from "store/hooks"
import { classNames, createSetter } from "utils"
import { EventConfigState } from "../types"

const TimeFields = ({ withIcons, ...props }: FormControlProps<EventConfigState["time"]> & { withIcons: boolean }) => {
    const {
        setValue,
        value: { date_on_timeline, isTimeless, end, start },
        classList,
        label,
        readOnly
    } = props
    const { t } = useTranslation("common")
    const selectorProps = {
        ...props,
        classList: classList,
        id: "date_on_timeline",
        allowedRange: "future",
        allowClear: false,
        value: date_on_timeline,
        label,
        disabled: readOnly,
        setValue: createSetter(setValue, "date_on_timeline")
    } as CalendarProps & FormControlProps<Date>
    const preferences = useCurrentPreferences()
    const { i18n } = useTranslation()
    return (
        <div className={classNames(classList.wrapper, "text-dark-blue")}>
            {withIcons ? (
                <DateSelectorWithIcons {...selectorProps} />
            ) : (
                <DateSelector {...selectorProps}>{formatDate(date_on_timeline, preferences, i18n)}</DateSelector>
            )}
            {isTimeless ? null : (
                <>
                    <div className="relative">
                        {t("time")}:
                        <InputMask
                            className="w-11 ml-4 border-none focus:ring-0 outline-0"
                            mask="99:99"
                            value={start}
                            disabled={readOnly}
                            onChange={(event) => setValue({ ...props.value, start: event.target.value })}
                            onBlur={(event) => {
                                const start = event.target.value;
                                let end = props.value.end;
                                if (end < start) end = start
                                setValue({ ...props.value, start, end })
                            }}
                        />
                        {/* <ErrorMessage text={errors.time} outOfFlow /> */}
                    </div>
                    <div>
                        {t("to")}
                        <InputMask
                            className="w-11 ml-2 border-none focus:ring-0 outline-0"
                            mask="99:99"
                            value={end}
                            disabled={readOnly}
                            onChange={(event) => setValue({ ...props.value, end: event.target.value })}
                            onBlur={(event) => {
                                let end = event.target.value;
                                const start = props.value.start
                                if (end < start) end = start
                                setValue({ ...props.value, end })
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default TimeFields
